// import 'bootstrap';
$('.js-modal_link').on('click', function(){
  const id = $(this).data('id');

  $('.m-modal').each(function(){
    if($(this).data('id') === id){
      $(this).fadeIn(300);
      return false;
    }
  });
});

$('.m-modal .close_btn').on('click', function(){
  $(this).parent('.m-modal').fadeOut(300);
});